import styled from "styled-components"

export const StyledSocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;

  @media only screen and (min-width: 960px) {
    justify-content: space-between;

    width: 300px;
  }
`

export const StyledSocialIcon = styled.a`
  color: #000000;
  width: 30px;
  height: 30px;

  &:hover {
    opacity: 0.6;
  }
`
