/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { ThemeProvider } from "styled-components"

import Header from "../Header"
import Footer from "../Footer"
import Seo from "../Seo"

import { GlobalStyle, StyledLayout } from "./styles"

const Layout = ({
  children,
  pageTitle,
  pageDescription,
  pageImage,
  isHomepage,
}) => {
  const data = useStaticQuery(graphql`
    query SettingsQuery {
      settings: allStoryblokEntry(
        filter: { field_component: { eq: "settings" } }
      ) {
        edges {
          node {
            name
            slug
            field_component
            full_slug
            content
          }
        }
      }
    }
  `)

  const settingsRaw = data.settings.edges[0].node

  const settings =
    typeof settingsRaw.content === "string"
      ? JSON.parse(settingsRaw.content)
      : settingsRaw.content

  const siteTitle = settings?.title || "Title"

  const theme = {
    breakpoints: {
      keys: {
        0: "xs",
        1: "sm",
        2: "md",
        3: "lg",
        4: "xl",
      },
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {},
    shape: {
      borderRadius: 10,
    },
    typography: {},
    transitions: {},
    zIndex: {
      appBar: 1100,
      modal: 1300,
    },
  }

  const fullscreen = (settings.fullscreen_homepage && isHomepage) || false
  return (
    <ThemeProvider theme={theme}>
      <Seo
        settings={settings}
        pageDescription={pageDescription}
        pageImage={pageImage}
        pageTitle={pageTitle}
      />
      <GlobalStyle theme="default" settings={settings} />
      <Header
        siteTitle={siteTitle}
        settings={settings}
        fullscreen={fullscreen}
      />
      <StyledLayout fullscreen={fullscreen} settings={settings}>
        <main>{children}</main>
        <Footer settings={settings} />
      </StyledLayout>
    </ThemeProvider>
  )
}

export default Layout
