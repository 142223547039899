import styled from "styled-components"
import { Link } from "gatsby"

export const StyledHeader = styled.header`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  position: ${({ $fullscreen }) => ($fullscreen ? "relative" : "fixed")};
  z-index: 10000;
  height: ${({ height }) => height};
  top: 0;
  left: 0;
  width: 100%;

  @media only screen and (min-width: 960px) {
    position: relative;
  }
`

export const StyledHeaderInner = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  color: #fff;
  height: 100%;

  @media only screen and (min-width: 960px) {
    flex-direction: row;
    padding: 0 1.0875rem;
  }
`

export const StyledLogo = styled.img`
  max-width: 80%;
  margin: 0 0 0 20px;
  height: 100%;

  @media only screen and (min-width: 960px) {
    max-width: 400px;
    margin: 0;
  }
`

export const StyledLogoLink = styled(Link)`
  background-color: #000;
  z-index: 500000;
  visibility: ${({ $fullscreen }) => ($fullscreen ? "hidden" : "initial")};
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  @media only screen and (min-width: 960px) {
    background-color: transparent;
  }
`
