import styled from "styled-components"
import Link from "gatsby-link"

export const StyledNavigationItem = styled.li`
  padding: 10px;
  margin: 10px 0;
  text-align: center;
`
export const StyledNavigationLink = styled(Link)`
  background-color: ${({ $cta, $ctaBackgroundColor }) =>
    $cta ? $ctaBackgroundColor : "transparent"};
  border-radius: ${({
    $cta,
    theme: {
      shape: { borderRadius },
    },
  }) => ($cta ? `${borderRadius * 2}px` : "none")};
  padding: ${({ $cta }) => ($cta ? "10px" : 0)};
  color: ${({ $linkColor }) => $linkColor};
  text-decoration: none;
  text-transform: uppercase;
  font-size: 20px;

  &:hover {
    opacity: 0.9;
  }

  @media only screen and (min-width: 960px) {
    font-size: 16px;
  }
`
