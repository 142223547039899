import * as React from "react"

import { StyledNavigationItem, StyledNavigationLink } from "./styles"

const Navigationitem = ({ name, to, cta, settings }) => {
  const ctaBackgroundColor =
    settings.button_cta_background_color.color || "transparent"

  const linkColor = cta
    ? settings.button_cta_color.color
    : settings.nav_link_color.color || "#fff"

  return (
    <StyledNavigationItem>
      <StyledNavigationLink
        to={to}
        prefetch="true"
        $cta={cta}
        $ctaBackgroundColor={ctaBackgroundColor}
        $linkColor={linkColor}
      >
        {name}
      </StyledNavigationLink>
    </StyledNavigationItem>
  )
}

export default Navigationitem
